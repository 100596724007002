/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_name">
      {{cvChatbotLlmNameLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_name"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_family">
      {{cvChatbotLlmFamilyLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_family"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_api_key">
      {{cvChatbotLlmApiKeyLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_api_key"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_file_path">
      {{cvChatbotLlmFilePathLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_file_path"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationchatbot_llm_is_active">
      {{cvChatbotLlmIsActiveLabel}}</label
    >
    <input
      v-model="vmChatBotLLMFormData.chatbot_llm_is_active"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="chatbot_llmAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { ChatBotLLMs } from "../../../FackApi/api/ChatBotLLM"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "ChatBotLLMAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add chatbot_llm",
      cvCardSubHeader: "Add chatbot_llm sub header",
      cvSubmitBtn: "Add",
      cvChatbotLlmNameLabel: "chatbot llm name",
      cvChatbotLlmFamilyLabel: "chatbot llm family",
      cvChatbotLlmApiKeyLabel: "chatbot llm api key",
      cvChatbotLlmFilePathLabel: "chatbot llm file path",
      cvChatbotLlmIsActiveLabel: "chatbot llm is active",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "ChatBotLLM Added",
      vmChatBotLLMFormData: Object.assign({}, this.initFormData()),
      vmChatBotLLMDesc: null,
      vmChatBotLLMType: null
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "chatbot_llm_name": "",
        "chatbot_llm_family": "",
        "chatbot_llm_api_key": "",
        "chatbot_llm_file_path": "",
        "chatbot_llm_is_active": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmChatBotLLMFormData) {
          if (!this.vmChatBotLLMFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * chatbot_llmAdd
     */
    async chatbot_llmAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let chatbotLlmAddResp = await ChatBotLLMs.chatbot_llmAdd(this, this.vmChatBotLLMFormData)
        await ApiResponse.responseMessageDisplay(this, chatbotLlmAddResp)
        if (chatbotLlmAddResp && !chatbotLlmAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseChatBotLLMAddModal", chatbotLlmAddResp.resp_data)
        }
        this.vmChatBotLLMFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at chatbot_llmAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
