<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="chatbot_llmAdd">{{cvbtnAddNew}}</b-button>
            <b-button variant="primary" class="ml-2" @click="goToTrainChatBot">{{cvbtnTrainBot}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchchatbot_llmWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="chatbot_llmObjList && chatbot_llmObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="chatbot_llmObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(chatbot_llm_name)="data">
                    {{data.item.chatbot_llm_name}} <br/>
                    <b-badge v-if="data.item.chatbot_llm_is_active === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm">
                      ACTIVE
                    </b-badge>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="activateLLM(data.item.cbllm_id)" v-if="!data.item.chatbot_llm_is_active" title="Activate LLM"><i class="ri-shut-down-line m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="showChatBotLLMEditDialog(data.item)" title="Edit LLM"><i class="ri-pencil-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelChatBotLLMAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <ChatBotLLMAdd :propOpenedInModal="true" @emitCloseChatBotLLMAddModal="closeChatBotLLMAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="chatbot_llmAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeChatBotLLMAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelChatBotLLMEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <ChatBotLLMEdit :propOpenedInModal="true" :propChatBotLLMObj="chatbot_llmEditObj" @emitCloseChatBotLLMEditModal="closeChatBotLLMEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="chatbot_llmEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeChatBotLLMEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelChatBotLLMDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelChatBotLLMDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="chatbot_llmDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { ChatBotLLMs } from "../../../FackApi/api/ChatBotLLM.js"
import ChatBotLLMAdd from "./ChatBotLLMAdd"
import ChatBotLLMEdit from "./ChatBotLLMEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "ChatBotLLMList",
  components: {
    ChatBotLLMAdd,
    ChatBotLLMEdit
  },
  data () {
    return {
      cvCardTitle: "Chatbot Large Language Models(LLM)",
      cvbtnAddNew: "Add New",
      cvbtnTrainBot: "Train Bot",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit ChatBotLLM",
      cvAddModalHeader: "Add ChatBotLLM",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Chat Bot LLM",
      showModelChatBotLLMAdd: false,
      showModelChatBotLLMEdit: false,
      showModelChatBotLLMDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },

        { label: "chatbot llm name", key: "chatbot_llm_name", class: "text-left align-text-top", sortable: true },
        { label: "chatbot llm family", key: "chatbot_llm_family", class: "text-left align-text-top", sortable: true },
        { label: "chatbot llm api key", key: "chatbot_llm_api_key", class: "text-left align-text-top", sortable: true },
        { label: "chatbot llm file path", key: "chatbot_llm_file_path", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      chatbot_llmObjList: [],
      chatbot_llmEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    socialvue.index()
    this.chatbot_llmList()
  },
  methods: {
    /**
     * searchchatbot_llmWithParams
     */
    async searchchatbot_llmWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.chatbot_llmList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.chatbot_llmList(true)
    },
    /**
     * chatbot_llmList
     */
    async chatbot_llmList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        let chatbotLlmListResp = await ChatBotLLMs.chatbot_llmList(this, this.whereFilter)
        if (chatbotLlmListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.chatbot_llmObjList = [ ...chatbotLlmListResp.resp_data.data ]
          }
          else {
            this.chatbot_llmObjList = [ ...this.chatbot_llmObjList, ...chatbotLlmListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.chatbot_llmObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.chatbot_llmObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = chatbotLlmListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_llmList() and Exception:", err)
      }
    },
    /**
     * chatbot_llmAdd
     */
    chatbot_llmAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/chatbot_llm_add")
        }
        else {
          this.showModelChatBotLLMAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_llmAdd() and Exception:", err.message)
      }
    },
    /**
     * chatbot_llmEdit
     */
    chatbot_llmEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/chatbot_llm_edit/" + this.chatbot_llmEditObj.cbllm_id)
        }
        else {
          this.chatbot_llmEditObj = item
          this.showModelChatBotLLMEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at chatbot_llmEdit() and Exception:", err.message)
      }
    },
    /**
     * showChatBotLLMDeleteDialog
     */
    showChatBotLLMDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelChatBotLLMDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showChatBotLLMDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * showChatBotLLMEditDialog
     */
    showChatBotLLMEditDialog (item) {
      try {
        this.chatbot_llmEditObj = item
        this.showModelChatBotLLMEdit = true
      }
      catch (err) {
        console.error("Exception occurred at showChatBotLLMEditDialog() and Exception:", err.message)
      }
    },
    /**
     * chatbot_llmDelete
     */
    async chatbot_llmDelete () {
      try {
        let chatbotLlmDelResp = await ChatBotLLMs.chatbot_llmDelete(this, this.delObj.cbllm_id)
        await ApiResponse.responseMessageDisplay(this, chatbotLlmDelResp)

        if (chatbotLlmDelResp && !chatbotLlmDelResp) {
          this.showModelChatBotLLMDelete = false
          return false
        }

        let index = this.chatbot_llmObjList.indexOf(this.delObj)
        this.chatbot_llmObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelChatBotLLMDelete = false
      }
      catch (err) {
        console.error("Exception occurred at chatbot_llmDelete() and Exception:", err.message)
      }
    },
    /**
     * closeChatBotLLMAddModal
     */
    closeChatBotLLMAddModal (chatbotLlmAddData) {
      try {
        if (chatbotLlmAddData) {
          if (this.chatbot_llmObjList && this.chatbot_llmObjList.length >= 1) {
            let chatbotLlmObjLength = this.chatbot_llmObjList.length
            let lastId = this.chatbot_llmObjList[chatbotLlmObjLength - 1]["id"]
            chatbotLlmAddData.id = lastId + 1
          }
          else {
            this.chatbot_llmObjList = []
            chatbotLlmAddData.id = 11111
          }

          chatbotLlmAddData.created_on = moment()
          this.chatbot_llmObjList.unshift(chatbotLlmAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelChatBotLLMAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closechatbot_llmAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeChatBotLLMEditModal
     */
    closeChatBotLLMEditModal () {
      try {
        this.showModelChatBotLLMEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeChatBotLLMEditModal() and Exception:", err.message)
      }
    },
    /**
     * goToTrainChatBot
    */
    goToTrainChatBot () {
      this.$router.push("/chatbot_data_src_list")
    },
    /**
     * activateLLM
     */
    async activateLLM (llmID) {
      try {
        const activationResp = await ChatBotLLMs.chatbot_llmSwitch(this, llmID)
        await ApiResponse.responseMessageDisplay(this, activationResp)
        if (activationResp.resp_status) {
          this.chatbot_llmObjList.forEach((e) => {
            if (e.cbllm_id == llmID) e.chatbot_llm_is_active = 1
            else e.chatbot_llm_is_active = 0
          })
        }
      }
      catch (err) {
        console.log("Exception in activateLLM and err: ", err)
      }
    }
  }
}
</script>
